import React, { useState, useEffect } from 'react';
import api from '../api/api';
import './ClassificacaoApontamentos.css';

function ClassificacaoApontamentos() {
  const [apontamentos, setApontamentos] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [novoApontamento, setNovoApontamento] = useState({
    id: '',
    nome: '',
    abreviacao: '',
    descricao: ''
  });
  const [editando, setEditando] = useState(null);
  const [erro, setErro] = useState(null);

  useEffect(() => {
    carregarApontamentos();
  }, []);

  const carregarApontamentos = async () => {
    try {
      const response = await api.get('/apontamentos');
      setApontamentos(response.data);
    } catch (error) {
      setErro('Erro ao carregar apontamentos');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editando) {
        await api.put(`/apontamentos/${editando}`, novoApontamento);
      } else {
        await api.post('/apontamentos', novoApontamento);
      }
      await carregarApontamentos();
      limparFormulario();
    } catch (error) {
      setErro('Erro ao salvar apontamento');
      console.error(error);
    }
  };

  const handleEdit = (apontamento) => {
    setEditando(apontamento._id);
    setNovoApontamento({
      id: apontamento.id,
      nome: apontamento.nome,
      abreviacao: apontamento.abreviacao,
      descricao: apontamento.descricao
    });
    setMostrarFormulario(true);
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir este apontamento?')) return;
    try {
      await api.delete(`/apontamentos/${id}`);
      await carregarApontamentos();
    } catch (error) {
      setErro('Erro ao excluir apontamento');
      console.error(error);
    }
  };

  const limparFormulario = () => {
    setNovoApontamento({
      id: '',
      nome: '',
      abreviacao: '',
      descricao: ''
    });
    setEditando(null);
    setMostrarFormulario(false);
  };

  return (
    <div className="classificacao-apontamentos">
      <div className="header">
        <h1>Classificação de Apontamentos</h1>
        {!mostrarFormulario && (
          <button 
            className="novo-apontamento-btn"
            onClick={() => setMostrarFormulario(true)}
          >
            Novo Apontamento
          </button>
        )}
      </div>
      
      {erro && <div className="erro">{erro}</div>}
      
      {mostrarFormulario ? (
        <form onSubmit={handleSubmit} className="apontamento-form">
          <div className="form-group">
            <label>ID:</label>
            <input
              type="text"
              value={novoApontamento.id}
              onChange={(e) => setNovoApontamento({...novoApontamento, id: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              value={novoApontamento.nome}
              onChange={(e) => setNovoApontamento({...novoApontamento, nome: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Abreviação:</label>
            <input
              type="text"
              value={novoApontamento.abreviacao}
              onChange={(e) => setNovoApontamento({...novoApontamento, abreviacao: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Descrição:</label>
            <textarea
              value={novoApontamento.descricao}
              onChange={(e) => setNovoApontamento({...novoApontamento, descricao: e.target.value})}
              required
            />
          </div>

          <div className="form-buttons">
            <button type="submit">{editando ? 'Atualizar' : 'Cadastrar'}</button>
            <button type="button" onClick={limparFormulario} className="cancelar">
              Cancelar
            </button>
          </div>
        </form>
      ) : (
        <div className="apontamentos-lista">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Abreviação</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {apontamentos.map(apontamento => (
                <tr key={apontamento._id}>
                  <td>{apontamento.id}</td>
                  <td>{apontamento.nome}</td>
                  <td>{apontamento.abreviacao}</td>
                  <td>{apontamento.descricao}</td>
                  <td>
                    <button onClick={() => handleEdit(apontamento)}>Editar</button>
                    <button onClick={() => handleDelete(apontamento._id)} className="excluir">Excluir</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ClassificacaoApontamentos;
