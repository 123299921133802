import React, { useState, useEffect } from 'react';
import api from '../api/api';
import './CadastroProjetos.css';
import { useNavigate } from 'react-router-dom';
import { useProjetoContext } from '../contexts/ProjetoContext';

function CadastroProjetos() {
  const [projetos, setProjetos] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [novoProjeto, setNovoProjeto] = useState({
    nome: '',
    nomeCliente: '',
    descricao: '',
    dataInicio: '',
    dataFim: '',
    status: 'Em andamento'
  });
  const [editando, setEditando] = useState(null);
  const [erro, setErro] = useState(null);
  const [mostrarDisciplinas, setMostrarDisciplinas] = useState(false);
  const [novaDisciplina, setNovaDisciplina] = useState({
    nome: '',
    responsavel: '',
    codigo: ''
  });
  const [editandoDisciplina, setEditandoDisciplina] = useState(null);
  const navigate = useNavigate();
  const { atualizarListaProjetos } = useProjetoContext();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    carregarProjetos();
  }, [navigate]);

  const carregarProjetos = async () => {
    try {
      const response = await api.get('/projetos');
      setProjetos(response.data);
    } catch (error) {
      setErro('Erro ao carregar projetos');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editando) {
        await api.put(`/projetos/${editando}`, novoProjeto);
      } else {
        await api.post('/projetos', novoProjeto);
      }
      await carregarProjetos();
      await atualizarListaProjetos();
      limparFormulario();
    } catch (error) {
      setErro('Erro ao salvar projeto');
      console.error(error);
    }
  };

  const handleEdit = (projeto) => {
    setEditando(projeto._id);
    setNovoProjeto({
      nome: projeto.nome,
      nomeCliente: projeto.nomeCliente,
      descricao: projeto.descricao || '',
      dataInicio: projeto.dataInicio ? projeto.dataInicio.split('T')[0] : '',
      dataFim: projeto.dataFim ? projeto.dataFim.split('T')[0] : '',
      status: projeto.status
    });
    setMostrarFormulario(true);
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir este projeto?')) return;
    try {
      await api.delete(`/projetos/${id}`);
      await carregarProjetos();
      await atualizarListaProjetos();
    } catch (error) {
      setErro('Erro ao excluir projeto');
      console.error(error);
    }
  };

  const limparFormulario = () => {
    setNovoProjeto({
      nome: '',
      nomeCliente: '',
      descricao: '',
      dataInicio: '',
      dataFim: '',
      status: 'Em andamento'
    });
    setEditando(null);
    setMostrarFormulario(false);
  };

  const handleAdicionarDisciplina = async () => {
    try {
      if (!editando) {
        setErro('É necessário estar editando um projeto para adicionar disciplinas');
        return;
      }

      if (!novaDisciplina.nome || !novaDisciplina.responsavel || !novaDisciplina.codigo) {
        setErro('Todos os campos da disciplina são obrigatórios');
        return;
      }

      console.log('Enviando disciplina:', novaDisciplina, 'para projeto:', editando);
      
      const response = await api.post(`/projetos/${editando}/disciplinas`, novaDisciplina);
      
      console.log('Resposta:', response.data);
      
      setProjetos(prevProjetos => 
        prevProjetos.map(p => p._id === editando ? response.data : p)
      );
      
      setNovaDisciplina({ nome: '', responsavel: '', codigo: '' });
      setErro(null);
    } catch (error) {
      console.error('Erro detalhado:', error);
      setErro(`Erro ao adicionar disciplina: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleEditarDisciplina = async (projetoId, disciplinaId) => {
    try {
      const response = await api.put(
        `/projetos/${projetoId}/disciplinas/${disciplinaId}`, 
        editandoDisciplina
      );
      setProjetos(prevProjetos => 
        prevProjetos.map(p => p._id === projetoId ? response.data : p)
      );
      setEditandoDisciplina(null);
    } catch (error) {
      setErro('Erro ao editar disciplina');
      console.error(error);
    }
  };

  const handleExcluirDisciplina = async (projetoId, disciplinaId) => {
    if (!window.confirm('Tem certeza que deseja excluir esta disciplina?')) return;
    try {
      const response = await api.delete(`/projetos/${projetoId}/disciplinas/${disciplinaId}`);
      setProjetos(prevProjetos => 
        prevProjetos.map(p => p._id === projetoId ? response.data : p)
      );
    } catch (error) {
      setErro('Erro ao excluir disciplina');
      console.error(error);
    }
  };

  const handleCopy = async (id) => {
    try {
      console.log('Iniciando cópia do projeto:', id);
      const response = await api.post(`/projetos/${id}/copy`);
      console.log('Resposta da cópia:', response.data);
      
      await carregarProjetos();
      await atualizarListaProjetos();
      setErro(null);
    } catch (error) {
      console.error('Erro detalhado ao copiar projeto:', error);
      setErro(`Falha ao copiar projeto: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className="cadastro-projetos">
      <div className="header">
        <h1>Cadastro de Projetos</h1>
        {!mostrarFormulario && (
          <button 
            className="novo-projeto-btn"
            onClick={() => setMostrarFormulario(true)}
          >
            Novo Projeto
          </button>
        )}
      </div>
      
      {erro && <div className="erro">{erro}</div>}
      
      {mostrarFormulario ? (
        <form onSubmit={handleSubmit} className="projeto-form">
          <div className="form-group">
            <label>Nome do Projeto:</label>
            <input
              type="text"
              value={novoProjeto.nome}
              onChange={(e) => setNovoProjeto({...novoProjeto, nome: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Cliente:</label>
            <input
              type="text"
              value={novoProjeto.nomeCliente}
              onChange={(e) => setNovoProjeto({...novoProjeto, nomeCliente: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Descrição:</label>
            <textarea
              value={novoProjeto.descricao}
              onChange={(e) => setNovoProjeto({...novoProjeto, descricao: e.target.value})}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Data de Início:</label>
              <input
                type="date"
                value={novoProjeto.dataInicio}
                onChange={(e) => setNovoProjeto({...novoProjeto, dataInicio: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Data de Fim:</label>
              <input
                type="date"
                value={novoProjeto.dataFim}
                onChange={(e) => setNovoProjeto({...novoProjeto, dataFim: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Status:</label>
              <select
                value={novoProjeto.status}
                onChange={(e) => setNovoProjeto({...novoProjeto, status: e.target.value})}
              >
                <option value="Em andamento">Em andamento</option>
                <option value="Concluído">Concluído</option>
                <option value="Cancelado">Cancelado</option>
              </select>
            </div>
          </div>

          <div className="disciplinas-section">
            <h3 onClick={() => setMostrarDisciplinas(!mostrarDisciplinas)} className="section-toggle">
              Disciplinas {mostrarDisciplinas ? '▼' : '▶'}
            </h3>
            
            {mostrarDisciplinas && (
              <>
                <div className="disciplinas-form">
                  <div className="form-row">
                    <div className="form-group">
                      <label>Nome da Disciplina:</label>
                      <input
                        type="text"
                        value={novaDisciplina.nome}
                        onChange={(e) => setNovaDisciplina({...novaDisciplina, nome: e.target.value})}
                      />
                    </div>
                    <div className="form-group">
                      <label>Responsável:</label>
                      <input
                        type="text"
                        value={novaDisciplina.responsavel}
                        onChange={(e) => setNovaDisciplina({...novaDisciplina, responsavel: e.target.value})}
                      />
                    </div>
                    <div className="form-group">
                      <label>Código:</label>
                      <input
                        type="text"
                        value={novaDisciplina.codigo}
                        onChange={(e) => setNovaDisciplina({...novaDisciplina, codigo: e.target.value.toUpperCase()})}
                      />
                    </div>
                  </div>
                  <button type="button" onClick={handleAdicionarDisciplina}>
                    Adicionar Disciplina
                  </button>
                </div>

                <table className="disciplinas-table">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Responsável</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editando && projetos.find(p => p._id === editando)?.disciplinas.map(disciplina => (
                      <tr key={disciplina._id}>
                        <td>{disciplina.codigo}</td>
                        <td>{disciplina.nome}</td>
                        <td>{disciplina.responsavel}</td>
                        <td>
                          <button type="button" onClick={() => handleEditarDisciplina(editando, disciplina._id)}>
                            Editar
                          </button>
                          <button type="button" onClick={() => handleExcluirDisciplina(editando, disciplina._id)}>
                            Excluir
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>

          <div className="form-buttons">
            <button type="submit">{editando ? 'Atualizar' : 'Cadastrar'}</button>
            <button type="button" onClick={limparFormulario} className="cancelar">
              Cancelar
            </button>
          </div>
        </form>
      ) : (
        <div className="projetos-lista">
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Cliente</th>
                <th>Status</th>
                <th>Data Início</th>
                <th>Data Fim</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {projetos.map(projeto => (
                <tr key={projeto._id}>
                  <td>{projeto.nome}</td>
                  <td>{projeto.nomeCliente}</td>
                  <td>{projeto.status}</td>
                  <td>{projeto.dataInicio ? new Date(projeto.dataInicio).toLocaleDateString() : '-'}</td>
                  <td>{projeto.dataFim ? new Date(projeto.dataFim).toLocaleDateString() : '-'}</td>
                  <td>
                    <button onClick={() => handleEdit(projeto)}>Editar</button>
                    <button onClick={() => handleDelete(projeto._id)} className="excluir">Excluir</button>
                    <button onClick={() => handleCopy(projeto._id)} className="copiar">Copiar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CadastroProjetos; 