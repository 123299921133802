import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import CadastroProjetos from './CadastroProjetos';
import ControleEntregaveis from './ControleEntregaveis';
import ChecklistVerificacao from './ChecklistVerificacao';
import ClassificacaoApontamentos from './ClassificacaoApontamentos';
import LicoesAprendidas from './LicoesAprendidas';
import './Layout.css';

const Layout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={`layout ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
      <Sidebar isCollapsed={isSidebarCollapsed} onToggle={toggleSidebar} />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<CadastroProjetos />} />
          <Route path="/controle-entregaveis" element={<ControleEntregaveis />} />
          <Route path="/checklist-verificacao" element={<ChecklistVerificacao />} />
          <Route path="/classificacao-apontamentos" element={<ClassificacaoApontamentos />} />
          <Route path="/licoes-aprendidas" element={<LicoesAprendidas />} />
        </Routes>
      </main>
    </div>
  );
};

export default Layout; 