import React, { useState, useEffect } from 'react';
import './ObservacoesSidebar.css';

const ObservacoesSidebar = ({ isOpen, atividade, onClose, onSave }) => {
  const [observacoes, setObservacoes] = useState('');

  useEffect(() => {
    if (isOpen && atividade?.observacoes) {
      setObservacoes(atividade.observacoes);
    } else if (!isOpen) {
      setObservacoes('');
    }
  }, [isOpen, atividade]);

  const handleClose = () => {
    setObservacoes('');
    onClose();
  };

  const handleSave = () => {
    onSave(observacoes);
    setObservacoes('');
  };

  if (!isOpen) return null;

  return (
    <div className="observacoes-sidebar">
      <h2>Observações</h2>
      <p>Item: {atividade?.nome}</p>
      <p>Número: {atividade?.numero}</p>
      <textarea
        value={observacoes}
        onChange={(e) => setObservacoes(e.target.value)}
        placeholder="Digite suas observações aqui..."
        rows="10"
      />
      <div className="sidebar-buttons">
        <button onClick={handleSave}>Salvar</button>
        <button onClick={handleClose}>Fechar</button>
      </div>
      <div className="debug-info">
        <p><strong>Debug Info:</strong></p>
        <p>ID: {atividade?._id}</p>
        <p>Nome: {atividade?.nome}</p>
        <p>Número: {atividade?.numero}</p>
        <p>Tem observações: {Boolean(atividade?.observacoes).toString()}</p>
        <p>Observações originais: {atividade?.observacoes || 'Nenhuma'}</p>
        <p>Observações no estado: {observacoes || 'Nenhuma'}</p>
      </div>
    </div>
  );
};

export default ObservacoesSidebar;
