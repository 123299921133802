import React, { useState, useEffect } from 'react';
import './LicoesSidebar.css';

const LicoesSidebar = ({ isOpen, detalhes, onClose }) => {
  const [conflito, setConflito] = useState('');
  const [resolucao, setResolucao] = useState('');

  useEffect(() => {
    if (isOpen && detalhes) {
      setConflito(detalhes.conflito || '');
      setResolucao(detalhes.resolucao || '');
    } else if (!isOpen) {
      setConflito('');
      setResolucao('');
    }
  }, [isOpen, detalhes]);

  const handleClose = () => {
    setConflito('');
    setResolucao('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="licoes-sidebar">
      <h2>Detalhes da Lição</h2>
      <p><strong>Conflito Encontrado:</strong></p>
      <p>{conflito || 'Nenhum conflito informado.'}</p>
      <p><strong>Resolução do Problema:</strong></p>
      <p>{resolucao || 'Nenhuma resolução informada.'}</p>
      <div className="sidebar-buttons">
        <button onClick={handleClose}>Fechar</button>
      </div>
    </div>
  );
};

export default LicoesSidebar;