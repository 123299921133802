import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProjetoSelector from './ProjetoSelector';
import './Sidebar.css';
import { useProjetoContext } from '../contexts/ProjetoContext';

const menuItems = [
  { path: '/', icon: 'home', text: 'Cadastro de Projetos' },
  { path: '/controle-entregaveis', icon: 'list', text: 'Controle de Entregáveis' },
  { path: '/checklist-verificacao', icon: 'check', text: 'Checklist de Verificação' },
  { path: '/classificacao-apontamentos', icon: 'flag', text: 'Classificação de Apontamentos' },
  { path: '/licoes-aprendidas', icon: 'lightbulb', text: 'Lições Aprendidas' }
];

function Sidebar() {
  const { 
    projetos, 
    atualizarListaProjetos, 
    projetoSelecionado,
    setProjetoSelecionado,
    sidebarCollapsed,
    setSidebarCollapsed
  } = useProjetoContext();

  useEffect(() => {
    atualizarListaProjetos();
  }, [atualizarListaProjetos]);

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
      <button className="toggle-sidebar" onClick={handleToggleSidebar}>
        {sidebarCollapsed ? '→' : '←'}
      </button>
      
      <div className="sidebar-content">
        <div className="sidebar-header">
          <h3>Projetos</h3>
        </div>
        <ProjetoSelector />
        <nav>
          <ul>
            {menuItems.map(item => (
              <li key={item.path}>
                <Link to={item.path}>
                  <i className={`fa fa-${item.icon}`}></i>
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
