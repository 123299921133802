import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../api/api';
import './Login.css';

function Login() {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErro('');
    try {
      const response = await api.post('/auth/login', { login, senha });
      const { token } = response.data;
      
      if (!token) {
        throw new Error('Token não recebido do servidor');
      }

      localStorage.setItem('token', token);
      console.log('Token armazenado:', token);
      
      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      console.error('Erro detalhado no login:', error);
      setErro(error.response?.data?.message || 'Erro ao fazer login');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          required
        />
        <button type="submit">Entrar</button>
        {erro && <p className="erro">{erro}</p>}
      </form>
    </div>
  );
}

export default Login;
