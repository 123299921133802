import React, { useState, useEffect } from 'react';
import api from '../api/api';
import './LicoesAprendidas.css';
import LicoesSidebar from './LicoesSidebar';

const TIPOLOGIAS = ['Torre Única', 'Múltiplas Torres', 'Horizontal', 'Misto', 'Outro'];
const ETAPAS = ['Projeto Básico', 'Projeto Executivo', 'Obra', 'Pós-Obra'];
const DISCIPLINAS = ['Furações', 'Cronograma', 'Contenção', 'Fundações', 'Estrutura', 'Arquitetura', 'Instalações'];

function LicoesAprendidas() {
  const [licoes, setLicoes] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [mostrarHistorico, setMostrarHistorico] = useState(false);
  const [licaoSelecionada, setLicaoSelecionada] = useState(null);
  const [erro, setErro] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [detalhesLicao, setDetalhesLicao] = useState(null);

  const [filtros, setFiltros] = useState({
    empreendimentoCliente: '',
    tipologia: '',
    disciplinasEnvolvidas: [],
    etapaProjeto: '',
    etapaVerificacao: ''
  });

  const [novaLicao, setNovaLicao] = useState({
    empreendimentoCliente: '',
    tipologia: '',
    disciplinasEnvolvidas: [],
    etapaProjeto: '',
    conflitoEncontrado: '',
    resolucaoProblema: '',
    licaoAprendida: '',
    etapaVerificacao: ''
  });

  useEffect(() => {
    carregarLicoes();
  }, []);

  const carregarLicoes = async () => {
    try {
      const response = await api.get('/licoes-aprendidas');
      setLicoes(response.data);
    } catch (error) {
      setErro('Erro ao carregar lições aprendidas');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (licaoSelecionada) {
        await api.put(`/licoes-aprendidas/${licaoSelecionada._id}`, novaLicao);
      } else {
        await api.post('/licoes-aprendidas', novaLicao);
      }
      await carregarLicoes();
      limparFormulario();
    } catch (error) {
      setErro(error.response?.data?.message || 'Erro ao salvar lição aprendida');
      console.error(error);
    }
  };

  const handleFiltrar = async () => {
    try {
      const params = new URLSearchParams();
      Object.entries(filtros).forEach(([key, value]) => {
        if (value && (Array.isArray(value) ? value.length > 0 : true)) {
          params.append(key, Array.isArray(value) ? value.join(',') : value);
        }
      });
      
      const response = await api.get(`/licoes-aprendidas/filtrar?${params}`);
      setLicoes(response.data);
    } catch (error) {
      setErro('Erro ao filtrar lições aprendidas');
      console.error(error);
    }
  };

  const handleEditar = (licao) => {
    setLicaoSelecionada(licao);
    setNovaLicao(licao);
    setMostrarFormulario(true);
  };

  const handleExcluir = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir esta lição aprendida?')) return;
    try {
      await api.delete(`/licoes-aprendidas/${id}`);
      await carregarLicoes();
    } catch (error) {
      setErro('Erro ao excluir lição aprendida');
      console.error(error);
    }
  };

  const limparFormulario = () => {
    setNovaLicao({
      empreendimentoCliente: '',
      tipologia: '',
      disciplinasEnvolvidas: [],
      etapaProjeto: '',
      conflitoEncontrado: '',
      resolucaoProblema: '',
      licaoAprendida: '',
      etapaVerificacao: ''
    });
    setLicaoSelecionada(null);
    setMostrarFormulario(false);
  };

  const limparFiltros = () => {
    setFiltros({
      empreendimentoCliente: '',
      tipologia: '',
      disciplinasEnvolvidas: [],
      etapaProjeto: '',
      etapaVerificacao: ''
    });
  };

  const handleVerDetalhes = (licao) => {
    setDetalhesLicao({
      conflito: licao.conflitoEncontrado,
      resolucao: licao.resolucaoProblema
    });
    setSidebarOpen(true);
  };

  return (
    <div className="licoes-aprendidas">
      <div className="header">
        <h1>Lições Aprendidas</h1>
        <div>
          <button 
            className="btn btn-primary"
            onClick={() => setMostrarFiltros(!mostrarFiltros)}
          >
            {mostrarFiltros ? 'Ocultar Filtros' : 'Mostrar Filtros'}
          </button>
          {!mostrarFormulario && (
            <button 
              className="btn btn-success"
              onClick={() => setMostrarFormulario(true)}
              style={{ marginLeft: '10px' }}
            >
              Nova Lição
            </button>
          )}
        </div>
      </div>

      {erro && <div className="erro">{erro}</div>}

      {mostrarFiltros && (
        <div className="filtros">
          <div className="filtros-grid">
            <div className="filtro-item">
              <label>Empreendimento/Cliente:</label>
              <input
                type="text"
                value={filtros.empreendimentoCliente}
                onChange={(e) => setFiltros({...filtros, empreendimentoCliente: e.target.value})}
              />
            </div>

            <div className="filtro-item">
              <label>Tipologia:</label>
              <select
                value={filtros.tipologia}
                onChange={(e) => setFiltros({...filtros, tipologia: e.target.value})}
              >
                <option value="">Todas</option>
                {TIPOLOGIAS.map(tipo => (
                  <option key={tipo} value={tipo}>{tipo}</option>
                ))}
              </select>
            </div>

            <div className="filtro-item">
              <label>Disciplinas:</label>
              <select
                multiple
                value={filtros.disciplinasEnvolvidas}
                onChange={(e) => setFiltros({
                  ...filtros,
                  disciplinasEnvolvidas: Array.from(e.target.selectedOptions, option => option.value)
                })}
              >
                {DISCIPLINAS.map(disc => (
                  <option key={disc} value={disc}>{disc}</option>
                ))}
              </select>
            </div>

            <div className="filtro-item">
              <label>Etapa do Projeto:</label>
              <select
                value={filtros.etapaProjeto}
                onChange={(e) => setFiltros({...filtros, etapaProjeto: e.target.value})}
              >
                <option value="">Todas</option>
                {ETAPAS.map(etapa => (
                  <option key={etapa} value={etapa}>{etapa}</option>
                ))}
              </select>
            </div>

            <div className="filtro-item">
              <label>Etapa de Verificação:</label>
              <select
                value={filtros.etapaVerificacao}
                onChange={(e) => setFiltros({...filtros, etapaVerificacao: e.target.value})}
              >
                <option value="">Todas</option>
                {ETAPAS.map(etapa => (
                  <option key={etapa} value={etapa}>{etapa}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="botoes-filtro">
            <button className="btn btn-primary" onClick={handleFiltrar}>Filtrar</button>
            <button className="btn btn-secondary" onClick={limparFiltros}>Limpar Filtros</button>
          </div>
        </div>
      )}

      {mostrarFormulario && (
        <form onSubmit={handleSubmit} className="form-licao">
          <div className="form-grid">
            <div className="form-group">
              <label>Empreendimento/Cliente:</label>
              <input
                type="text"
                value={novaLicao.empreendimentoCliente}
                onChange={(e) => setNovaLicao({...novaLicao, empreendimentoCliente: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Tipologia:</label>
              <select
                value={novaLicao.tipologia}
                onChange={(e) => setNovaLicao({...novaLicao, tipologia: e.target.value})}
                required
              >
                <option value="">Selecione...</option>
                {TIPOLOGIAS.map(tipo => (
                  <option key={tipo} value={tipo}>{tipo}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Disciplinas Envolvidas:</label>
              <select
                multiple
                value={novaLicao.disciplinasEnvolvidas}
                onChange={(e) => setNovaLicao({
                  ...novaLicao,
                  disciplinasEnvolvidas: Array.from(e.target.selectedOptions, option => option.value)
                })}
                required
              >
                {DISCIPLINAS.map(disc => (
                  <option key={disc} value={disc}>{disc}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Etapa do Projeto:</label>
              <select
                value={novaLicao.etapaProjeto}
                onChange={(e) => setNovaLicao({...novaLicao, etapaProjeto: e.target.value})}
                required
              >
                <option value="">Selecione...</option>
                {ETAPAS.map(etapa => (
                  <option key={etapa} value={etapa}>{etapa}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Conflito Encontrado:</label>
              <textarea
                value={novaLicao.conflitoEncontrado}
                onChange={(e) => setNovaLicao({...novaLicao, conflitoEncontrado: e.target.value})}
                maxLength={500}
                required
              />
            </div>

            <div className="form-group">
              <label>Resolução do Problema:</label>
              <textarea
                value={novaLicao.resolucaoProblema}
                onChange={(e) => setNovaLicao({...novaLicao, resolucaoProblema: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Lição Aprendida:</label>
              <textarea
                value={novaLicao.licaoAprendida}
                onChange={(e) => setNovaLicao({...novaLicao, licaoAprendida: e.target.value})}
                maxLength={300}
                required
              />
            </div>

            <div className="form-group">
              <label>Etapa a ser Verificada:</label>
              <select
                value={novaLicao.etapaVerificacao}
                onChange={(e) => setNovaLicao({...novaLicao, etapaVerificacao: e.target.value})}
                required
              >
                <option value="">Selecione...</option>
                {ETAPAS.map(etapa => (
                  <option key={etapa} value={etapa}>{etapa}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-buttons">
            <button type="submit" className="btn btn-primary">
              {licaoSelecionada ? 'Atualizar' : 'Cadastrar'}
            </button>
            <button 
              type="button" 
              className="btn btn-secondary"
              onClick={limparFormulario}
            >
              Cancelar
            </button>
          </div>
        </form>
      )}

      <table className="tabela-licoes">
        <thead>
          <tr>
            <th>Empreendimento/Cliente</th>
            <th>Tipologia</th>
            <th>Disciplinas</th>
            <th>Etapa</th>
            <th>Lição Aprendida</th>
            <th>Etapa Verificação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {licoes.map(licao => (
            <tr key={licao._id}>
              <td>{licao.empreendimentoCliente}</td>
              <td>{licao.tipologia}</td>
              <td>{licao.disciplinasEnvolvidas.join(', ')}</td>
              <td>{licao.etapaProjeto}</td>
              <td>{licao.licaoAprendida}</td>
              <td>{licao.etapaVerificacao}</td>
              <td>
                <div className="acoes">
                  <button 
                    className="btn btn-primary"
                    onClick={() => handleEditar(licao)}
                  >
                    Editar
                  </button>
                  <button 
                    className="btn btn-danger"
                    onClick={() => handleExcluir(licao._id)}
                  >
                    Excluir
                  </button>
                  <button 
                    className="btn btn-secondary"
                    onClick={() => {
                      setLicaoSelecionada(licao);
                      setMostrarHistorico(true);
                    }}
                  >
                    Histórico
                  </button>
                  <button 
                    className="btn btn-info"
                    onClick={() => handleVerDetalhes(licao)}
                  >
                    Detalhes
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {mostrarHistorico && licaoSelecionada && (
        <div className="historico-modal">
          <div className="historico-content">
            <h2>Histórico de Alterações</h2>
            {licaoSelecionada.historico.map((item, index) => (
              <div key={index} className="historico-item">
                <p>
                  <strong>Usuário:</strong> {item.usuario.login}
                  <br />
                  <strong>Ação:</strong> {item.acao}
                  <br />
                  <strong>Data:</strong> {new Date(item.data).toLocaleString()}
                </p>
                {item.alteracoes && (
                  <div>
                    <strong>Alterações:</strong>
                    <pre>{JSON.stringify(item.alteracoes, null, 2)}</pre>
                  </div>
                )}
              </div>
            ))}
            <button 
              className="btn btn-secondary"
              onClick={() => setMostrarHistorico(false)}
            >
              Fechar
            </button>
          </div>
        </div>
      )}

      <LicoesSidebar
        isOpen={sidebarOpen}
        detalhes={detalhesLicao}
        onClose={() => setSidebarOpen(false)}
      />
    </div>
  );
}

export default LicoesAprendidas; 