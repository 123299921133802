import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';

const ProjetoContext = createContext();

export function ProjetoProvider({ children }) {
  const [projetoSelecionado, setProjetoSelecionado] = useState(null);
  const [projetos, setProjetos] = useState([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const atualizarListaProjetos = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      
      const response = await api.get('/projetos');
      setProjetos(response.data);
    } catch (error) {
      console.error('Erro ao atualizar lista de projetos:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    atualizarListaProjetos();
  }, [atualizarListaProjetos, navigate]);

  const selecionarProjeto = useCallback((projeto) => {
    setProjetoSelecionado(projeto);
  }, []);

  return (
    <ProjetoContext.Provider value={{
      projetoSelecionado,
      projetos,
      setProjetoSelecionado,
      selecionarProjeto,
      atualizarListaProjetos,
      sidebarCollapsed,
      setSidebarCollapsed,
      loading
    }}>
      {children}
    </ProjetoContext.Provider>
  );
}

export function useProjetoContext() {
  const context = useContext(ProjetoContext);
  if (!context) {
    throw new Error('useProjetoContext deve ser usado dentro de um ProjetoProvider');
  }
  return context;
} 