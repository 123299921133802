import React, { useEffect } from 'react';
import { useProjetoContext } from '../contexts/ProjetoContext';
import api from '../api/api';

function ProjetoSelector() {
  const { 
    projetos, 
    projetoSelecionado, 
    setProjetoSelecionado, 
    atualizarListaProjetos 
  } = useProjetoContext();

  useEffect(() => {
    atualizarListaProjetos();
  }, [atualizarListaProjetos]);

  const handleProjetoChange = (event) => {
    const projetoId = event.target.value;
    const projetoSelecionado = projetos.find(p => p._id === projetoId);
    setProjetoSelecionado(projetoSelecionado || null);
  };

  return (
    <select
      value={projetoSelecionado?._id || ''}
      onChange={handleProjetoChange}
      className="projeto-select"
    >
      <option value="">Selecione um projeto</option>
      {projetos.map(projeto => (
        <option key={projeto._id} value={projeto._id}>
          {projeto.nome}
        </option>
      ))}
    </select>
  );
}

export default ProjetoSelector; 